<template>
  <v-dialog
      :value="dialog"
      @click:outside="close"
      max-width="800px"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        {{ $t('system.order_details') }}
        <v-btn
            class="mt-1 ml-3"
            color="secondary"
            text
            fab
            small
            @click.stop="close"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="mt-1 mb-2" />
      <v-skeleton-loader
          :class="{' mt-4 ml-4 mr-4': loading}"
          type="article, actions"
          :loading="loading"
          style="background-color: white"
      >
        <v-card-text
            class="card-text pb-2 pl-6 pr-6"
        >
          <p class="text--primary title mb-2">
            {{ data.account.name }}
          </p>
          <v-spacer />
          <p class="text--primary">
            <v-icon
                size="16"
                class="mr-2"
                style="width: 24px; margin-top: -3px"
            >fas fa-calendar
            </v-icon>
            {{ data.ordered_at }}
          </p>
          <p class="text--primary">
            {{ $t('system.send_confirmation') }}:
            <strong>{{ send_confirmation ? $t('system.yes') : $t('system.no') }}</strong>
          </p>
          <v-divider />
          <p class="mb-2">
            <strong>{{ $t('system.positions') }}:</strong>
          </p>
          <div v-if="!data.positions.length > 0">{{ $t('system.no_data') }}</div>
          <div
              v-for="(element, index) in data.positions"
              :key="index"
              class="d-flex justify-space-between"
          >
            <span>{{ index + 1 }}. {{ element.name }}</span>&nbsp;
            <span>{{ $t('system.item_price') }}: {{ element.total_currency }} {{ element.item_price }}</span>&nbsp;
            <span>{{ $t('system.quantity') }}: {{ element.quantity }}</span>&nbsp;
            <span>{{ $t('system.total_price') }}: {{ element.total_currency }} {{ element.items_price }}</span>
          </div>
          <p class="mt-4">
            <strong>{{ $t('system.summary') }}:</strong><br>
            {{ data.total_currency }} {{ data.total_value }}
          </p>
          <v-divider />
          <p>
            <strong>{{ $t('system.note') }}:</strong><br>
            {{ data.note ? data.note : $t('system.no_data') }}
          </p>
        </v-card-text>
        <v-divider
            class="mt-1 mb-0"
        />
        <v-card-actions
            class="pb-3 pt-3 pr-3 d-flex justify-space-between"
        >
          <div v-if="data.visit">
            <router-link
                :to="`/visits/${data.visit}`"
                tag="v-btn"
            >
              <v-btn
                  text
              >{{ $t('system.go_to_visit') }}
              </v-btn>
            </router-link>
          </div>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'VisitDetails',

  data() {
    return {
      dialogStatus: false,
      accounts: [],
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async toggleEdit() {
      this.$emit('edit', this.data.id);
    },

    close() {
      this.$emit('close');
    },

    isInColumn(key, column) {
      const first = ['account_group', 'account_priority', 'route', 'visit_type', 'contact', 'contact_phone', 'assigned_to'];
      const second = ['target', 'last_month_sales', 'average_three_months', 'current_month_sales', 'last_visit'];
      if (column === 'first') {
        return first.find(element => element === key);
      } else if (column === 'second') {
        return second.find(element => element === key);
      }
    },
  },
};
</script>

<style
    scoped
    lang="scss"
>

.text--primary {
  font-size: 17px;

  &.title {
    font-size: 30px;
  }
}

</style>
