<template>
  <div class="view-container">
    <div
        class="view"
    >
      <div class="view-body">
        <v-skeleton-loader
            class="mx-auto"
            type="table"
            :loading="loading && !formLoaded"
        >
          <v-data-table
              :footer-props="dataTableOptions.footerProps"
              :options.sync="dataTableOptions"
              :server-items-length="dataTableOptions.totalItems"
              :headers="headers"
              :items="data"
              :loading="loading"
              class="elevation-0 clickable-row"
              @click:row="viewOrder"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-btn
                    normal
                    class="buttonCustom"
                    dark
                    elevation="0"
                    @click="toggleCreateOrder"
                >
                  <v-icon
                      size="17"
                      class="mr-2"
                  >fas fa-plus
                  </v-icon>
                  {{ $t('system.add_order') }}
                </v-btn>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />
                <v-spacer />
                <SimpleGroupBy
                    :group-by="dataTableOptions.groupBy[0]"
                    :group-by-elements="groupByElements"
                    :default-group-by="'ordered_at'"
                    @setGroupBy="setGroupBy"
                />
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />
                <v-btn
                    class="mt-1 mr-1"
                    color="secondary"
                    small
                    text
                    @click.stop="fetchData(true)"
                >
                  <v-icon size="17">fas fa-sync-alt</v-icon>
                </v-btn>
                <SimpleStaticFilters
                    :filter-params="filterParams"
                    @setFilters="setFilters"
                />
              </v-toolbar>
            </template>
            <template v-slot:item.account_priority_name="{ item }">
              <v-chip
                  :color="item.account_priority_color"
                  dark
              >
                {{ item.account_priority_name }}
              </v-chip>
            </template>
            <template v-slot:item.transaction_status="{ item }">
              <strong>{{ item.transaction_status }}</strong>
            </template>
            <template v-slot:item.last_visit_status="{ item }">
              <v-icon :color="item.last_visit_status ? 'green' : 'red'">fas fa-car</v-icon>
            </template>
            <template v-slot:item.ordered_at_time="{ item }">
              {{ item.ordered_at }} {{ item.ordered_at_time }}
            </template>
            <template v-slot:no-data>
              {{ $t('system.no_data') }}
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
      <OrderDetails
          :data="orderData"
          :dialog="viewDialog"
          :loading="dialogLoading"
          @close="close"
          @edit="toggleEditOrderAlt"
      />
      <CreateOrder
          :accounts="accounts"
          :products="products"
          :dialog="editDialog"
          :loading="dialogLoading"
          :create="create"
          @close="close"
          @save="saveOrder"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SimpleGroupBy from '@/components/Filters/SimpleGroupBy';
import SimpleStaticFilters from '@/components/Filters/SimpleStaticFilters';
import OrderDetails from '@/components/Orders/OrderDetails';
import CreateOrder from '@/components/Orders/CreateOrder';

export default {
  name: 'OrdersList',

  components: {
    SimpleGroupBy,
    SimpleStaticFilters,
    OrderDetails,
    CreateOrder,
  },

  data() {
    return {
      loading: true,
      formLoaded: false,
      viewDialog: false,
      editDialog: false,
      create: false,
      groupByElements: ['ordered_at', 'account_name', 'account_group_name'],
      filterParams: [
        {
          type: 'date-range',
          name: ['order_date_from', 'order_date_to'],
          menu: false,
          value: [],
        }, {
          type: 'autocomplete',
          name: 'account_group',
          items: [],
          value: null,
        }, {
          type: 'autocomplete',
          name: 'account_name',
          items: [],
          value: null,
        },
      ],
      data: [],
      orderData: {},
      editOrderData: {},
      products: [],
      accounts: [],
      groups: [],
      dialogLoading: false,
      dataTableOptions: {
        page: 1,
        itemsPerPage: localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`) ? parseInt(JSON.parse((localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`))), 10) : 10,
        sortBy: ['ordered_at'],
        sortDesc: [true],
        groupBy: localStorage.getItem(`${this.$route.name.trim()}_GroupBy`) && JSON.parse(localStorage.getItem(`${this.$route.name.trim()}_GroupBy`)) ? [JSON.parse(localStorage.getItem(`${this.$route.name.trim()}_GroupBy`))] : [],
        groupDesc: [true],
        multiSort: false,
        mustSort: false,
        totalItems: 0,
        footerProps: {
          itemsPerPageOptions: [10, 25, 50, 100],
          showFirstLastPage: true,
        },
      },
      headers: [
        {
          text: this.$t('system.account_group_name'),
          sortable: true,
          value: 'account_group_name',
        },
        {
          text: this.$t('system.account_name'),
          sortable: true,
          value: 'account_name',
        },
        {
          text: this.$t('system.account_priority_name'),
          sortable: true,
          value: 'account_priority_name',
        },
        {
          text: this.$t('system.transaction_status'),
          sortable: true,
          value: 'transaction_status',
        },
        {
          text: this.$t('system.last_visit_status'),
          sortable: true,
          value: 'last_visit_status',
        },
        {
          text: this.$t('system.author'),
          sortable: true,
          value: 'author',
        },
        {
          text: this.$t('system.ordered_at'),
          sortable: true,
          value: 'ordered_at_time',
        },
        {
          text: this.$t('system.total_value'),
          sortable: true,
          value: 'total_value',
        },
      ],
      requestUrl: '',
    };
  },

  watch: {
    dataTableOptions: {
      handler() {
        if (this.formLoaded) {
          this.prepareQuery();
          this.fetchData();
          localStorage.setItem(`${this.$route.name.trim()}_ItemsPerPage`, JSON.stringify(this.dataTableOptions.itemsPerPage));
        }
      },
      deep: true,
    },
    $route() {
      if (this.$route.params.id) {
        this.getOrder(this.$route.params.id);
      }
    },
  },

  beforeMount() {
    this.fetchData(true);
    if (this.$route.name === 'AddOrder') {
      this.viewDialog = true;
      this.create = true;
    } else if (this.$route.params.id) {
      this.viewDialog = true;
      this.getOrder(this.$route.params.id);
    }
  },

  methods: {
    async fetchData(deep = false) {
      this.loading = true;
      if (deep) {
        this.fetchFilterForms();
      }
      try {
        const {data} = await axios.get(`/sk_visits/order${this.requestUrl}`, {
          params: {
            per_page: this.dataTableOptions.itemsPerPage,
            page: this.dataTableOptions.page,
            direction: this.dataTableOptions.sortDesc[0] ? 'desc' : 'asc',
            column: this.dataTableOptions.sortBy[0],
          },
        });
        this.data = this.parseObj(data.data);
        this.dataTableOptions.totalItems = data.total;
        this.formLoaded = true;
        this.loading = false;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    prepareQuery() {
      this.requestUrl = `?order=${this.dataTableOptions.groupBy[0] ? this.dataTableOptions.groupBy[0] === 'account_group' ? 'groups' : this.dataTableOptions.groupBy[0] : 'ordered_at'}`;
      this.filterParams.forEach((filterParam) => {
        if (filterParam.name === 'account_name' && filterParam.value) {
          filterParam.value.forEach((element) => {
            this.requestUrl += `&account_ids[]=${element}`;
          });
        } else if (filterParam.name === 'account_group' && filterParam.value) {
          filterParam.value.forEach((element) => {
            this.requestUrl += `&account_groups_ids[]=${element}`;
          });
        } else if (Array.isArray(filterParam.name) && filterParam.value) {
          if (filterParam.name[0] === 'order_date_from' && filterParam.value[0]) {
            this.requestUrl += `&order_date_from=${filterParam.value[0]}`;
          }
          if (filterParam.name[1] === 'order_date_to' && filterParam.value[1]) {
            this.requestUrl += `&order_date_to=${filterParam.value[1]}`;
          }
        }
      });
    },

    async getOrder(id) {
      this.viewDialog = true;
      this.dialogLoading = true;
      try {
        const {data} = await axios.get(`/sk_visits/order/${id}`);
        this.orderData = data.data;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.dialogLoading = false;
      }
    },

    viewOrder(element) {
      this.orderData = element;
      if (this.$route.params.id !== element.id) {
        this.$router.push({
          path: `/orders/${element.id}`,
        });
      }
    },

    close() {
      this.$router.push({
        path: `/orders`,
      });
      this.viewDialog = false;
      this.editDialog = false;
      this.dialogLoading = false;
      this.editOrderData = {};
    },

    toggleEditOrder(id) {
      this.editDialog = true;
      this.viewDialog = false;
      if (this.$route.params.id !== id) {
        this.$router.push({
          path: `/orders/${id}`,
        });
      }
    },

    toggleEditOrderAlt(id) {
      this.editDialog = true;
      this.viewDialog = false;
      this.getOrder(id);
    },

    async toggleCreateOrder() {
      this.editDialog = true;
      this.viewDialog = false;
    },

    async saveOrder(data) {
      this.dialogLoading = true;
      try {
        await axios.post(`/sk_visits/order`, data);
        await this.fetchData();
        this.close();
      } catch (error) {
        console.log('error', error);
      } finally {
        this.dialogLoading = false;
      }
    },

    fetchFilterForms() {
      const savedParams = localStorage.getItem(`${this.$route.name.trim()}_Filters`) ? JSON.parse(localStorage.getItem(`${this.$route.name.trim()}_Filters`)) : null;
      if (savedParams)
        this.filterParams.forEach((param) => {
          savedParams.forEach((localParam) => {
            if (localParam.name === param.name) {
              param.value = localParam.value;
            }
          });
        });
      this.prepareQuery();
      this.getAccounts();
      this.getAccountsGroups();
      this.getProducts();
    },

    async getProducts() {
      try {
        const {data} = await axios.get('/sk_visits/product', {
          params: {
            page: 1,
            per_page: 200,
          },
        });
        this.products = data.products.data;
      } catch (error) {
        console.log('error', error);
      }
    },

    async getAccounts() {
      try {
        const {data} = await axios.get('/sk_visits/accounts');
        this.filterParams.forEach((element) => {
          if (element.name === 'account_name') {
            element.items = data;
          }
        });
        this.accounts = data;
      } catch (error) {
        console.log('error', error);
      }
    },

    async getAccountsGroups() {
      try {
        const {data} = await axios.get('/sk_visits/groups');
        this.filterParams.forEach((element) => {
          if (element.name === 'account_group') {
            element.items = data;
          }
        });
        this.groups = data;
      } catch (error) {
        console.log('error', error);
      }
    },

    parseObj(obj) {
      let arr = [];
      Object.values(obj).forEach((parent) => {
        parent.forEach((child) => {
          child.ordered_at_time = child.ordered_at.split(' ')[1];
          child.ordered_at = child.ordered_at.split(' ')[0];
          arr.push(child);
        });
      });
      return arr;
    },

    setGroupBy(groupBy) {
      if (this.dataTableOptions.groupBy[0] !== groupBy) {
        this.loading = true;
        this.dataTableOptions.groupBy = groupBy ? [groupBy] : [];
        this.prepareQuery();
        this.fetchData();
        localStorage.setItem(`${this.$route.name.trim()}_GroupBy`, JSON.stringify(groupBy));
      }
    },

    setFilters(filterParams) {
      this.filterParams = filterParams;
      const copy = JSON.parse(JSON.stringify(filterParams));
      copy.forEach((element) => {
        if (element.items) delete element.items;
      });
      this.loading = true;
      this.prepareQuery();
      this.fetchData();
      localStorage.setItem(`${this.$route.name.trim()}_Filters`, JSON.stringify(copy));
    },
  },
};
</script>
