<template>
  <div class="d-flex flex-column groupBy">
    <small>{{ $t(title) }}</small>
    <div>
      <v-btn
          v-for="(element, index) in groupByElements"
          :key="index"
          @click.stop="setGroupBy(element)"
          x-small
          :color="element === groupBy ? 'primary' : ''"
          :text="element !== groupBy"
      >
        {{ $t(`system.${element}`) }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleGroupBy',

  props: {
    title: {
      type: String,
      default: 'system.group_by',
    },
    groupBy: {
      type: String,
      default: null,
    },
    groupByElements: {
      type: Array,
      default: () => [],
    },
    defaultGroupBy: {
      type: String,
      default: null,
    },
  },

  methods: {
    setGroupBy(element) {
      if (this.groupBy === element)
        this.$emit('setGroupBy', null);
      else
        this.$emit('setGroupBy', element);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
>

.groupBy {
  position: relative;
  padding-top: 12px;

  small {
    top: -5px;
    position: absolute;
  }
}

</style>
