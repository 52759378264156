var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-container"},[_c('div',{staticClass:"view"},[_c('div',{staticClass:"view-body"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table","loading":_vm.loading && !_vm.formLoaded}},[_c('v-data-table',{staticClass:"elevation-0 clickable-row",attrs:{"footer-props":_vm.dataTableOptions.footerProps,"options":_vm.dataTableOptions,"server-items-length":_vm.dataTableOptions.totalItems,"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading},on:{"update:options":function($event){_vm.dataTableOptions=$event},"click:row":_vm.viewOrder},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"buttonCustom",attrs:{"normal":"","dark":"","elevation":"0"},on:{"click":_vm.toggleCreateOrder}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("fas fa-plus ")]),_vm._v(" "+_vm._s(_vm.$t('system.add_order'))+" ")],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('SimpleGroupBy',{attrs:{"group-by":_vm.dataTableOptions.groupBy[0],"group-by-elements":_vm.groupByElements,"default-group-by":'ordered_at'},on:{"setGroupBy":_vm.setGroupBy}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mt-1 mr-1",attrs:{"color":"secondary","small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.fetchData(true)}}},[_c('v-icon',{attrs:{"size":"17"}},[_vm._v("fas fa-sync-alt")])],1),_c('SimpleStaticFilters',{attrs:{"filter-params":_vm.filterParams},on:{"setFilters":_vm.setFilters}})],1)]},proxy:true},{key:"item.account_priority_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.account_priority_color,"dark":""}},[_vm._v(" "+_vm._s(item.account_priority_name)+" ")])]}},{key:"item.transaction_status",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.transaction_status))])]}},{key:"item.last_visit_status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.last_visit_status ? 'green' : 'red'}},[_vm._v("fas fa-car")])]}},{key:"item.ordered_at_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ordered_at)+" "+_vm._s(item.ordered_at_time)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('system.no_data'))+" ")]},proxy:true}])})],1)],1),_c('OrderDetails',{attrs:{"data":_vm.orderData,"dialog":_vm.viewDialog,"loading":_vm.dialogLoading},on:{"close":_vm.close,"edit":_vm.toggleEditOrderAlt}}),_c('CreateOrder',{attrs:{"accounts":_vm.accounts,"products":_vm.products,"dialog":_vm.editDialog,"loading":_vm.dialogLoading,"create":_vm.create},on:{"close":_vm.close,"save":_vm.saveOrder}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }