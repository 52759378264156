<template>
  <v-dialog
      :value="dialog"
      :persistent="true"
      max-width="700px"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        <v-skeleton-loader
            type="table-cell"
            :loading="loading"
            style="background-color: white"
        >
          <span>{{ $t('system.create_order') }}</span>
        </v-skeleton-loader>
        <v-btn
            class="mt-1 ml-3"
            color="secondary"
            text
            fab
            small
            @click.stop="close"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="mt-1 mb-0" />
      <v-skeleton-loader
          :class="{'mt-4 ml-4 mr-4': loading}"
          type="article, actions"
          :loading="loading"
          style="background-color: white"
      >
        <v-card-text
            class="card-text"
        >
          <v-form
              @keyup.native.enter="valid && save()"
              v-model="valid"
              ref="form"
          >
            <v-switch
                v-model="formData.send_confirmation"
                class="mt-1"
            >
              <template v-slot:label>
                <span
                    class="mt-2"
                    style="color: rgba(0, 0, 0, 0.7)"
                >
                  {{ $t('system.send_confirmation') }}
                </span>
              </template>
            </v-switch>
            <v-autocomplete
                v-model="formData.account_id"
                :items="accounts"
                :label="$t('system.account_name')"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
                auto-select-first
                dense
                outlined
                flat
                @change="getContacts(formData.account_id)"
            />
            <v-autocomplete
                v-model="formData.contact_id"
                :items="contacts"
                :label="$t('system.contact')"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
                auto-select-first
                dense
                outlined
                flat
                :loading="contactsLoading ? 'blue' : false"
                :readonly="!formData.account_id || contactsLoading || contacts.length === 0"
                :disabled="!formData.account_id || contactsLoading || contacts.length === 0"
                :hint="!formData.account_id ? `${$t('system.account_name_is_required')}`: formData.account_id && contacts.length === 0 ? `${$t('system.no_data')}` : ''"
                persistent-hint
            />
            <v-divider />
            <div v-if="positionsError && !formData.positions.length > 0">
              <span style="color: darkred">{{ $t('system.at_least_one_product_required') }}</span>
            </div>
            <div
                v-for="(element, index) in formData.positions"
                :key="index"
                class="d-flex justify-space-between line-hover-shadow px-1 py-2"
            >
              <span
                  class="mt-1"
                  style="font-size: 16px;"
              >
                {{ index + 1 }}. {{ products.find((e) => e.id === element.product_id).name }}
              </span>
              <div>
                {{ $t('system.quantity') }}:
                <input
                    v-model="element.quantity"
                    title="quantity"
                    class="quantity-input"
                    type="number"
                    min="1"
                    max="100000"
                    step="1"
                    dense
                    outlined
                />
              </div>

              <v-btn
                  icon
                  text
                  color="red"
                  small
                  @click="formData.positions.splice(index, 1)"
              >
                <v-icon size="18">fas fa-times</v-icon>
              </v-btn>
            </div>
            <div class="mt-2 w-100" />
            <v-autocomplete
                v-model="selectedProduct"
                :items="filteredProducts"
                :label="$t('system.add_product')"
                item-text="name"
                item-value="id"
                auto-select-first
                dense
                outlined
                flat
                persistent-hint
                @change="addProduct"
            />
          </v-form>
        </v-card-text>
        <v-divider
            class="mt-0 mb-0"
        />
        <v-card-actions
            class="pb-3 pt-3 pr-3 d-flex justify-content-end"
        >
          <v-btn
              text
              color="primary"
              @click="close"
          >
            {{ $t('system.cancel') }}
          </v-btn>
          <v-btn
              color="primary"
              @click="save"
          >
            {{ $t('system.save') }}
          </v-btn>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>

import axios from 'axios';

export default {
  name: 'CreateOrder',

  data() {
    return {
      dialogStatus: false,
      valid: false,
      contactsLoading: false,
      contacts: [],
      selectedProduct: null,
      positionsError: false,
      formData: {
        account_id: null,
        contact_id: null,
        send_confirmation: true,
        client_wants_order: true,
        positions: [],
      },
      rules: {
        required: value => !!value || this.$t('field_must_be_valid'),
      },
    };
  },

  computed: {
    filteredProducts() {
      return this.products
          .filter((element) => !this.formData.positions
              .find((position) => element.id === position.product_id));
    },
  },

  props: {
    products: {
      type: Array,
      default: () => [],
    },
    accounts: {
      type: Array,
      default: () => [],
    },
    dialog: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },

  watch: {
    dialog: {
      handler(val) {
        if (!val) {
          this.clearFormData();
        }
      },
    },
  },

  methods: {
    addProduct(product) {
      if (product) {
        this.formData.positions.push({
          product_id: product,
          quantity: 1,
        });
      }
      this.$nextTick(() => {
        this.selectedProduct = null;
      });
    },

    close() {
      this.$emit('close');
    },

    save() {
      if (!this.formData.positions.length > 0) {
        this.positionsError = true;
        return;
      }
      this.fixProductsQuantity();
      this.$refs.form.validate();
      if (this.valid) {
        this.$emit('save', this.formData);
        console.log(this.formData);
        this.$refs.form.resetValidation();
      }
    },

    fixProductsQuantity() {
      this.formData.positions.forEach((element) => {
        if (!element.quantity || element.quantity < 1) {
          element.quantity = 1;
        }
        element.quantity = Math.round(element.quantity);
      });
    },

    async getContacts(id) {
      this.contactsLoading = true;
      try {
        const response = await axios.get(`/sk_visits/contacts/${id}`);
        this.contacts = response.data;
        this.contactsLoading = false;
        if (this.formData.contact_id) {
          this.formData.contact_id = null;
        }
      } catch (error) {
        console.log('error', error);
        this.contactsLoading = false;
      }
    },

    clearFormData() {
      this.formData = {
        account_id: null,
        contact_id: null,
        send_confirmation: true,
        client_wants_order: true,
        positions: [],
      };
    },
  },
};
</script>

<style
    scoped
    lang="scss"
>

.line-hover-shadow {
  transition-duration: .2s;

  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }
}

.quantity-input {
  width: 80px;
  border: 1px solid rgba(0, 0, 0, .5);
  border-radius: 3px;
  padding: 1px 6px;
  font-size: 16px;
}

</style>
